import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/NewCTA.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/space-hero-ui/Navbar.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/lib/svg_renderer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Lexend\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/oceanwide/oceanwide-semibold.woff2\",\"variable\":\"--oceanwide-font\"}],\"variableName\":\"oceanwide\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/sf_pro/sf_pro.woff2\",\"display\":\"swap\",\"variable\":\"--sf-pro\"}],\"variableName\":\"sf_pro\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/sonner/dist/index.mjs")
import Link from 'next/link';
import Image from 'next/image';

const DropDownBig = ({ visible, setVisible, navlist , dark }) => {
  return (
    <div
      className={`absolute w-full max-w-[90vw] mx-auto z-40 mt-[2px] ${dark && '!bg-[#031416]'} backdrop-blur-sm rounded-xl px-10 gap-3 ${
        visible
          ? 'max-h-screen overflow-visible py-5'
          : 'max-h-0 overflow-hidden'
      } grid grid-cols-2 transition-all duration-300 ease-in-out left-0 right-0 top-20 `}
    >
      {navlist?.map((item, index) => {
        return (
          <Link key={index} prefetch={false} href={item?.url}>
            <div
              className={`rounded-xl group w-full ${
                visible && 'animate-fade-in-down'
              }  border border-white/20 hover:border-[#3cafc1] transition-all duration-150 px-8 py-4 h-[110px] relative flex items-start justify-center `}
            >
              <Image
                alt="img"
                src={'/assets/arrow.svg'}
                width={200}
                height={300}
                className="w-4 absolute group-hover:translate-x-1 transition-all duration-300 top-4 right-5"
              />

              <div>
                <h1 className="text-lg font-semibold text-white">
                  {item?.heading}
                </h1>
                <p className="text-sm font-light text-gray-300 mt-1 line-clamp-2">
                  {item?.description}
                </p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default DropDownBig;
